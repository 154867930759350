import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class SaveforlaterInfo<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * No description
	 *
	 * @tags saveforlater-info
	 * @name SaveForLaterInfoDetail
	 * @summary Get all the products which are added in save for later list.
	 * @request GET:/store/{storeId}/saveForLater/info
	 * @secure
	 * @response `200` `any` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session isn't valid.
	 * @response `403` `void` The user isn't authorized to perform the specified request.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	saveForLaterInfoDetail = (storeId: string, params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['saveForLaterInfoDetail'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/saveForLater/info `,
				params: _params,
				methodName: 'saveForLaterInfoDetail',
			};
			logger.trace(traceData);
		}
		return this.http.request<any, void>({
			path: `/store/${storeId}/saveForLater/info `,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}
