/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Linkable } from '@/components/blocks/Linkable';
import { SessionErrorType } from '@/data/types/Error';
import { Typography } from '@mui/material';
// import Link from 'next/link';
import { FC, createContext, useState, PropsWithChildren } from 'react';

export type SessionErrorContextType = {
	sessionError: SessionErrorType | null;
	setSessionError: (error: SessionErrorType | null) => void;
	sessrionErrorMsg: JSX.Element;
};

export const SessionErrorContext = createContext<SessionErrorContextType>(
	{} as SessionErrorContextType
);

export const SessionErrorProvider: FC<PropsWithChildren> = ({ children }) => {
	const [sessionError, setSessionError] = useState<SessionErrorType | null>(null);
	const action = (<Linkable href='/login' variant='body2Bold' key="link">{'sign in'}</Linkable>);
	// eslint-disable-next-line react/jsx-no-literals
	const sessrionErrorMsg = (<Typography variant='body2' sx={{color: 'heading.dark'}}>For security reasons, your session has timed out. Please {action} again.</Typography>);


	return (
		<SessionErrorContext.Provider value={{ sessionError, setSessionError, sessrionErrorMsg }}>
			{children}
		</SessionErrorContext.Provider>
	);
};
